<template>
  <div class="login-view">
    <div class="login-box">
      <div class="title">
        <div class="t1">华资数字人管理后台</div>
        <div class="t2">欢迎登录</div>
      </div>
      <div class="pwdRegister_content login-content">
        <el-form
          :model="ruleForm"
          ref="pwdRegister"
          status-icon
          :rules="pwdRegRules"
        >
          <el-form-item status-icon prop="telNum">
            <el-input
              prefix-icon="el-icon-user"
              placeholder="请输入账号/手机号"
              maxlength="11"
              v-model="ruleForm.telNum"
            ></el-input>
          </el-form-item>
          <el-form-item prop="pwd">
            <el-input
              prefix-icon="el-icon-lock"
              placeholder="请输入密码"
              type="password"
              v-model="ruleForm.pwd"
              @keyup.enter.native="throttleLogin"
            ></el-input>
          </el-form-item>
          <el-form-item size="large">
            <div
              class="btn"
              @click="throttleLogin"
              :disabled="loading"
              :id="ruleForm.pwd && ruleForm.telNum ? 'confirmBtn' : ''"
              v-loading.fullscreen.lock="loading"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              element-loading-text="登录中..."
            >
              登录
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      ruleForm: {
        telNum: "",
        pwd: "",
      },
      pwdRegRules: {
        telNum: [
          {
            required: true,
            trigger: "blur",
            message: "请输入账号/手机号",
          },
        ],
        pwd: [
          {
            required: true,
            trigger: "blur",
            message: "请输入密码",
          },
        ],
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    throttleLogin() {
      this.$refs["pwdRegister"].validate((valid) => {
        if (valid) {
          this.loading = true;
          let cs = this.ruleForm;
          if (cs.telNum == "13692240353" && cs.pwd == "hztx188") {
            this.getToken();
          } else {
            this.$message.error("账号或密码错误，请重试");
          }
          this.loading = false;
        }
      });
    },
    //获取token
    getToken() {
      let params = {};
      this.http
        .post("/open/company/gwskip/token", params)
        .then((res) => {
          if (res.data.code == 200) {
            this.$message.success("登录成功！");
            this.setCacheData("token", res.data.data);
            setTimeout(() => {
              this.$router.push("/Home");
            }, 500);
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => console.log(err));
    },
  },
  components: {},
};
</script>

<style lang="less" scoped>
.el-input {
  font-size: 16px;
}
/deep/.el-input__inner {
  height: 60px !important;
  font-size: 16px;
}
.pwdRegister_content {
  margin-top: 50px;
  #confirmBtn {
    background: #006eff;
  }

  .btn {
    cursor: pointer;
    height: 60px;
    width: 100%;
    border-radius: 4px;
    background: #d8d8d8;
    color: #ffffff;
    text-align: center;
    line-height: 60px;
    font-size: 18px;
  }

  .otherRegister {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 35px;
    font-size: 16px;
    .toVerify {
      color: #006eff;
      float: left;
      cursor: pointer;
    }

    .toForget {
      cursor: pointer;
      color: #666666;
      float: right;
    }
  }
}
.login-view {
  .login-box {
    .title {
      .t2 {
        font-size: 28px;
      }
      .t1 {
        font-size: 38px;
        margin-bottom: 30px;
      }
      font-weight: bold;
      color: white;
      text-align: center;
    }
    width: 423px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 80px 60px;
  }
  background: url("../../images/back/back.png") no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
}
</style>
