<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
// import broadside from "./components/broadside.vue";
export default {
  name: "app",
  components: {
    // broadside,
  },
  mounted() {
    // process.on('unhandledRejection', error => {
    // 	console.error('unhandledRejection', error);
    // 	process.exit(1) // To exit with a 'failure' code
    // });
  },
};
</script>

<style lang="less">
.el-pagination {
  float: right;
  font-weight: 500 !important;
  margin: 60px auto 0;
}

.el-pager .active {
  background-color: #66b1ff !important;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.FadeInTwo {
  animation: fadeInUpTwo 1000ms both;
}
.FadeNone {
  opacity: 0;
}

/* 由下而上淡入 */
.FadeIn {
  animation: fadeInUp 1000ms both;
}

/* 从左侧淡入 */
.fadeInLeft {
  animation: fadeInLeft 1000ms both;
}

.slideIn {
  animation: slideInRight 1000ms both;
}

/* 由底部旋转淡入 */
.rotateIn {
  animation: rotateInLeft 1000ms both;
}

/* 放大10倍淡入 */
.puffBigIn {
  animation: puffBigIn 1500ms both;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes puffBigIn {
  0% {
    transform: scale3d(10, 10, 10);
    opacity: 0;
  }

  100% {
    transform: none;
    opacity: 1;
  }
}

@keyframes rotateInLeft {
  from {
    transform-origin: center 80%;
    transform: rotate3d(0, 0, 1, -200deg);
  }

  to {
    transform-origin: center 80%;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes floatY {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-5px);
  }
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translate3d(30%, 0, 0);
    visibility: visible;
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUpTwo {
  from {
    opacity: 0;
    transform: translate3d(0, 10%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes jump {
  from,
  10% {
    transform: translateY(0) scale3d(1, 1, 1);
  }

  30% {
    transform: translateY(10%) scale3d(1.05, 0.8, 1);
  }

  50% {
    transform: translateY(-50%) scale3d(1, 1.1, 1);
  }

  65% {
    transform: translateY(0) scale3d(1, 1, 1);
  }

  75% {
    transform: translateY(8%) scale3d(1.08, 0.84, 1);
  }

  85% {
    transform: translateY(6%) scale3d(1.05, 0.88, 1);
  }

  90%,
  to {
    transform: none;
  }
}
.el-select .el-input.is-focus .el-input__inner {
  border-color: #66b1ff !important;
}

.el-select-dropdown .selected {
  color: #66b1ff !important;
}

body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}

body {
  background: #fff;
  -webkit-text-size-adjust: 100%;
}

a {
  color: #2d374b;
  text-decoration: none;
}

a:hover {
  color: #66b1ff;
  text-decoration: underline;
}

em {
  font-style: normal;
}

li {
  list-style: none;
}

img {
  border: 0;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

p {
  word-wrap: break-word;
}
/* // 设置滚动条整体样式 */
::-webkit-scrollbar {
  width: 10px;
}

/* / 滚动条方块样式 */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
  cursor: pointer;
}

/* // 滚动条轨道样式 */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: white;
}
/*commonDialog弹窗样式*/
.commonDialog {
  .el-input,
  .el-select,
  .el-cascader {
    width: 100%;
    height: 34px !important;

    .el-input__inner {
      height: 34px !important;
    }

    .el-input__suffix,
    .el-input__prefix {
      .el-input__icon {
        line-height: 34px !important;
      }
    }
  }
}

.commonDialog,
.modal {
  .el-dialog__body {
    padding: 20px !important;
  }

  .el-dialog {
    position: absolute;
    margin-top: 0 !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .el-dialog__header {
      position: relative;
      padding: 20px !important;

      //border-bottom: 2px solid #C8F2FF;
      .el-dialog__title {
        font-size: 20px;
        font-weight: bold;
        color: #66b1ff;
        float: left;
      }

      .el-dialog__headerbtn {
        // top: 33px;
        // font-size: 20px;
        top: 8px;
        font-size: 34px;

        .el-dialog__close {
          color: #66b1ff;
        }

        .el-dialog__close:before {
          font-weight: 600;
        }
      }
    }

    .el-dialog__header:before {
      position: absolute;
      width: calc(100% - 40px);
      height: 2px;
      background: #66b1ff;
      content: "";
      left: 20px;
      top: 58px;
    }

    .el-dialog__body {
      .el-select {
        width: 100%;
      }
    }

    // .divider {
    //   height: 1px;
    //   background-color: rgba(248, 248, 248, 1);
    //   width: 100%;
    //   margin-bottom: 20px;
    //   margin-top: 0px;

    //   &.noBottom {
    //     margin-bottom: 0px;
    //   }
    // }
    .el-dialog__footer {
      padding: 0px 20px 30px;
      text-align: center;
      // .dialog-footer {
      // }
    }
  }
}
</style>
