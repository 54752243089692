import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login/index.vue'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'login',
  component: login
}, {
  path: "/Home",
  redirect: "/Home/homepage",
  component: () => import("@/views/Home.vue"),
  meta: {
    title: "首页",
  },
  children: [{
    path: "homepage",
    component: () => import("@/views/homepage/index.vue"),
    meta: {
      title: "账号总览",
    }
  }]
}]

const router = new VueRouter({
  routes
})

export default router